// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Routes
import { adminUsersAddURL, adminUsersBaseURL, adminUsersPasswordBaseURL } from 'routes/admin';

// Services
import { fetchUsers, deleteUser } from 'services/users';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_USERS_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminUsersView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = `Users ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchUsers().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const buttons = [
    {
      id: 1,
      btnText: 'Add user',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: adminUsersAddURL,
    },
  ];

  return (
    <AdminList
      buttons={buttons}
      title={title}
      tableCols={ADMIN_USERS_COLS}
      tableData={data}
      field="username"
      baseURL={adminUsersBaseURL}
      passwordBtn
      passwordLink={adminUsersPasswordBaseURL}
      deleteFn={deleteUser}
      refreshFn={fetchData}
    />
  );
};

export default AdminUsersView;
