// React
import React, { useContext, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminTagsURL } from 'routes/admin';

// Services
import { addTag } from 'services/tags';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminTagsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    setIsLoading(false);

    if (!renderAfterCalled.current) {
      setTitleSEO(`Add - Tags ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    type: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
  });
  const fields = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'type',
      label: 'Type',
      typeField: 'textfield',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields}
      formikFn={addTag}
      formikRedirect={adminTagsURL}
      formikBtnText="Add tag"
    />
  );
};

export default AdminTagsAddView;
