// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchImportantReplays } from 'services/replays';

// Constants
import { HIDE_TOP_TOOLBAR } from 'constants/mrt';
import { REPLAYS_COLS } from 'constants/tableHead';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid } from '@mui/material';

const ReplaysView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [titles, setTitles] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Replays';

  const fetchData = () => {
    setIsLoading(true);

    fetchImportantReplays().then((res) => {
      setData1(res?.data?.col1 || []);
      setData2(res?.data?.col2 || []);
      setData3(res?.data?.col3 || []);
      setTitles(res.titles || []);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();

      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const columns = useMemo(() => REPLAYS_COLS, []);
  const table1 = useMaterialReactTable({
    columns,
    data: data1,
    ...HIDE_TOP_TOOLBAR,
  });
  const table2 = useMaterialReactTable({
    columns,
    data: data2,
    ...HIDE_TOP_TOOLBAR,
  });
  const table3 = useMaterialReactTable({
    columns,
    data: data3,
    ...HIDE_TOP_TOOLBAR,
  });

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Grid container>
          <Grid item xs={12} md={6} xl={4}>
            <PageTitle title={titles[0]} variant="h6" component="h2" />
            <MaterialReactTable table={table1} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <PageTitle title={titles[1]} variant="h6" component="h2" />
            <MaterialReactTable table={table2} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <PageTitle title={titles[2]} variant="h6" component="h2" />
            <MaterialReactTable table={table3} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReplaysView;
