// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminPatchesURL } from 'routes/admin';

// Services
import { fetchPatchesGameAvailable, addPatch } from 'services/patches';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminPatchesAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [options, setOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchPatchesGameAvailable().then((res) => {
      setOptions(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Patches ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    patches: [],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    patches: Yup.array(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'patches',
      label: 'Patches',
      typeField: 'multiautocomplete',
      options,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFn={addPatch}
      formikRedirect={adminPatchesURL}
      formikBtnText="Add patch"
    />
  );
};

export default AdminPatchesAddView;
