// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminItemsURL } from 'routes/admin';

// Services
import { fetchSingleItem, updateItem } from 'services/items';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminItemsEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleItem(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.name} - Items ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    id: data?.id || '',
    name: data?.name || '',
    image_url: data?.image_url || '',
    ornn_item: data?.ornn_item || false,
  };
  const validationSchema = Yup.object().shape({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
    image_url: Yup.string().url().required('Required'),
    ornn_item: Yup.boolean(),
  });
  const fields = [
    {
      id: 'id',
      label: 'ID',
      typeField: 'textfield',
    },
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
    {
      id: 'ornn_item',
      label: 'Ornn Item',
      typeField: 'checkbox',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields}
      formikFn={updateItem}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminItemsURL}
      formikBtnText="Update item"
    />
  );
};

export default AdminItemsEditView;
