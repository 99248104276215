// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { libraryBasicURL } from 'routes/app';

// Services
import { fetchSingleLibrary, updateLibrary } from 'services/library';
import { fetchTags } from 'services/tags';
import { fetchGameDataForLibrary } from 'services/games';

// Utils
import { secondsToHms } from 'utils/time';

// Constants
import { GAME_LIBRARY_DATA } from 'constants/team';

// Schemas
import { STRING_SCHEMA, STRING_REQUIRED_SCHEMA, BOOLEAN_SCHEMA } from 'schemas/base';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const LibraryEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [teamSide, setTeamSide] = useState(false);
  const [currentGame, setCurrentGame] = useState(JSON.parse(JSON.stringify(GAME_LIBRARY_DATA)));
  const [currentTags, setCurrentTags] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchTags('VOD').then((res) => setTagOptions(res));

    fetchSingleLibrary(id).then((res) => {
      setData(res);

      setTeamSide(res?.team_id === res?.game?.red_team_id);

      const tags =
        res?.tags &&
        res?.tags.map(({ tag }) => ({
          id: tag.id,
          name: tag.name,
        }));

      if (tags) {
        tags.sort((a, b) => a.name.localeCompare(b.name));
      }

      setCurrentTags(tags);

      setIsLoading(false);

      fetchGameDataForLibrary(res?.game_id).then((game) => {
        setCurrentGame(game);
      });
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO('Edit Library Link');
    }
  }, [data]);

  const initialValues = {
    user_id: data?.user_id || '',
    game_id: (data?.game_id && data?.game_id.toString()) || '',
    is_red_side: teamSide,
    url: data?.url || '',
    game_time: (data?.game_time && secondsToHms(data?.game_time)) || '',
    tags: currentTags || [],
    note: data?.note || '',
  };
  const validationSchemaDict = {
    user_id: STRING_REQUIRED_SCHEMA,
    game_id: STRING_REQUIRED_SCHEMA,
    is_red_side: BOOLEAN_SCHEMA,
    url: Yup.string().url().required('Required'),
    game_time: STRING_REQUIRED_SCHEMA,
    note: STRING_SCHEMA,
  };

  const validationSchema = Yup.object().shape(validationSchemaDict);

  const fields1 = [
    {
      id: 'url',
      label: 'URL',
      typeField: 'textfield',
    },
    {
      id: 'game_time',
      label: 'Game Time',
      typeField: 'textfield',
    },
  ];

  const fields2 = [
    {
      id: 'game_id',
      typeField: 'game',
      loadData: {
        game: currentGame,
      },
    },
    {
      id: 'is_red_side',
      label: 'Side',
      type: 'side',
      typeField: 'switch',
      mt: 2,
    },
  ];

  const fields3 = [
    {
      id: 'tags',
      label: 'Tags',
      typeField: 'multiautocomplete',
      options: tagOptions,
      disabled: false,
      freeSolo: true,
      newItemType: 'VOD',
    },
    {
      id: 'note',
      label: 'Note',
      typeField: 'textfield',
      rows: 5,
      multiline: true,
    },
  ];

  return data && Object.keys(data).length > 0 ? (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFn={updateLibrary}
      formikAdditionalFnArgs={[id]}
      formikRedirect={libraryBasicURL}
      formikBtnText="Update link"
    />
  ) : null;
};

export default LibraryEditView;
