// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminPlayerUrnsURL } from 'routes/admin';

// Services
import { fetchPlayers } from 'services/players';
import { addPlayerUrn } from 'services/playerUrns';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminPlayerUrnsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [options, setOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchPlayers().then((res) => {
      setOptions(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Player URNs ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    urn: '',
    player: null,
    start_date: '',
    end_date: '',
  };
  const validationSchema = Yup.object().shape({
    urn: Yup.string().required('Required'),
    player: Yup.object().required('Required'),
    start_date: Yup.string(),
    end_date: Yup.string(),
  });
  const fields1 = [
    {
      id: 'urn',
      label: 'Urn',
      typeField: 'textfield',
    },
    {
      id: 'player',
      label: 'Player',
      typeField: 'autocomplete',
      options,
    },
    {
      id: 'start_date',
      label: 'Start',
      typeField: 'textfield',
    },
    {
      id: 'end_date',
      label: 'End',
      typeField: 'textfield',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFn={addPlayerUrn}
      formikRedirect={adminPlayerUrnsURL}
      formikBtnText="Add player urn"
    />
  );
};

export default AdminPlayerUrnsAddView;
