// React
import React, { useState, useEffect, useMemo } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { HIDE_TOP_TOOLBAR, ROW_NUMBERS } from 'constants/mrt';
import { LIBRARY_SELECT_GAME_COLS, ROW_ACTIONS_PROPS } from 'constants/tableHead';
import { GAME_LIBRARY_DATA } from 'constants/team';

// Elements
import { Box, Button, Modal, Backdrop } from '@mui/material';
import GameSelectInfo from 'components/atoms/GameSelectInfo/GameSelectInfo';
import DraftData from 'components/molecules/DraftData/DraftData';
import GameSelectSearch from 'components/molecules/GameSelectSearch/GameSelectSearch';

// Component
const GameSelect = ({ id, value, setFieldValue, loadData }) => {
  const [open, setOpen] = useState(false);
  const [games, setGames] = useState([]);
  const [data, setData] = useState(JSON.parse(JSON.stringify(GAME_LIBRARY_DATA)));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const selectGame = (row) => {
    setFieldValue(id, row.original.id.toString());
    setData({
      id: row.original.id,
      date: row.original.date,
      type: row.original.type,
      team_1: row.original.name_1,
      team_2: row.original.name_2,
      blue_picks: row.original.blue_picks,
      red_picks: row.original.red_picks,
    });

    handleClose();
  };

  useEffect(() => {
    if (loadData && loadData.game) {
      setData(loadData.game);
    }
  }, [loadData]);

  const columns = useMemo(() => LIBRARY_SELECT_GAME_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data: games,
    ...HIDE_TOP_TOOLBAR,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Button variant="contained" color="primary" size="small" onClick={() => selectGame(row)}>
        Select
      </Button>
    ),
    displayColumnDefOptions: {
      ...ROW_NUMBERS.displayColumnDefOptions,
      'mrt-row-actions': ROW_ACTIONS_PROPS,
    },
  });

  return (
    <>
      <Box mt={1}>
        <input type="hidden" id={id} value={value} />
        <Box display="flex">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            {data && data.id ? 'Change game' : 'Select game'}
          </Button>
          <GameSelectInfo
            date={data.date}
            type={data.type}
            team_1={data.team_1}
            team_2={data.team_2}
          />
        </Box>
        <Box display="flex" mt={1}>
          <Box display="flex">
            <DraftData value={data.blue_picks} />
          </Box>
          <Box display="flex" ml={2}>
            <DraftData value={data.red_picks} />
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            p: 4,
          }}
        >
          <GameSelectSearch setData={setGames} />
          <MaterialReactTable table={table} />
        </Box>
      </Modal>
    </>
  );
};

GameSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  loadData: PropTypes.object,
};

GameSelect.defaultProps = {
  loadData: {},
};

export default GameSelect;
