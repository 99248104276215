// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import { Box, Typography } from '@mui/material';
import ReasonData from 'components/molecules/ReasonData/ReasonData';

// Component
const PerformanceData = ({ data, isCol2 }) => (
  <Box mt={2}>
    {data.map(({ time, start_time, reason, game, data: itemData }, index) => {
      const reasons = reason.split(' | ');
      const itemTime = time || start_time;
      let title = null;

      if (index === 0 || game !== data[index - 1].game) {
        title = (
          <Typography variant="h6" component="p" mt={2} fontSize={16}>
            Game {game}
          </Typography>
        );
      }

      return (
        <Box key={`${game}_${itemTime}`}>
          {title}
          <Box display="grid" gridTemplateColumns="50px auto" mt={index > 0 ? 1 : 0}>
            <Typography variant="body2" component="p" mr={1}>
              {secondsToHms(itemTime)}
            </Typography>
            <Box>
              {reasons.map((item) => (
                <Box
                  key={item}
                  display="grid"
                  gridTemplateColumns={isCol2 === true ? '3fr 1fr' : '8fr 2fr'}
                >
                  <Typography variant="body2" component="p">
                    {item}
                  </Typography>
                  <ReasonData reason={item} data={itemData} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      );
    })}
  </Box>
);

PerformanceData.propTypes = {
  data: PropTypes.array.isRequired,
  isCol2: PropTypes.bool,
};

PerformanceData.defaultProps = {
  isCol2: false,
};

export default PerformanceData;
