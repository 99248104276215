// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography } from '@mui/material';

// Component
const GameSelectInfo = ({ date, type, team_1, team_2 }) => (
  <Box ml={2}>
    <Typography variant="body2" component="p">
      {date} - {type}
    </Typography>
    <Typography variant="body2" component="p">
      {team_1} vs. {team_2}
    </Typography>
  </Box>
);

GameSelectInfo.propTypes = {
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  team_1: PropTypes.string.isRequired,
  team_2: PropTypes.string.isRequired,
};

export default GameSelectInfo;
