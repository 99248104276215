// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminChampionsURL } from 'routes/admin';

// Services
import { fetchSingleChampion, updateChampion } from 'services/champions';
import { fetchTags } from 'services/tags';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminChampionsEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchTags('Champion').then((res) => {
      setOptions(res);
    });

    fetchSingleChampion(id).then((res) => {
      setData(res);

      const tags = res.tags.map(({ tag }) => ({
        id: tag.id,
        name: tag.name,
      }));

      tags.sort((a, b) => a.name.localeCompare(b.name));

      setCurrentTags(tags);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.name} - Champions ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    id: data?.id || '',
    name: data?.name || '',
    api_name: data?.api_name || '',
    image_url: data?.image_url || '',
    tags: currentTags || [],
  };
  const validationSchema = Yup.object().shape({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
    api_name: Yup.string().required('Required'),
    image_url: Yup.string().url().required('Required'),
  });
  const fields = [
    {
      id: 'id',
      label: 'ID',
      typeField: 'textfield',
    },
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'api_name',
      label: 'API Name',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
    {
      id: 'tags',
      label: 'Tags',
      typeField: 'multiautocomplete',
      options,
      optionLabel: 'name',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields}
      formikFn={updateChampion}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminChampionsURL}
      formikBtnText="Update champion"
    />
  );
};

export default AdminChampionsEditView;
