// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Yup
import * as Yup from 'yup';

// Routes
import { adminTiersURL } from 'routes/admin';

// Services
import {
  fetchSingleTier,
  updateTier,
  importAccountsForTier,
  addRankedGamesForTier,
} from 'services/tiers';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Assets
import RefreshIcon from '@mui/icons-material/Refresh';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminTiersEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleTier(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  const importAccounts = () => {
    setBtnLoading(true);

    importAccountsForTier(id).then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      setBtnLoading(false);
    });
  };

  const addRankedGames = () => {
    setBtnLoading(true);

    addRankedGamesForTier(id).then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.name} - Tiers ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    name: data?.name || '',
    ladder: data?.ladder || false,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    ladder: Yup.boolean(),
  });
  const fields = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'ladder',
      label: 'Ladder',
      typeField: 'checkbox',
    },
  ];
  const buttons = [
    {
      id: 1,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import accounts',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importAccounts,
    },
    {
      id: 2,
      loadingState: btnLoading,
      loadingText: 'Adding...',
      btnText: 'Add ranked games',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: addRankedGames,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields}
      formikFn={updateTier}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminTiersURL}
      formikBtnText="Update tier"
      additionalBtns={buttons}
    />
  );
};

export default AdminTiersEditView;
