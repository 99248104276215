// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { performanceBasicURL } from 'routes/app';

// Services
import { addPerformanceReport } from 'services/performance';
import { fetchCurrentUserTeamData, fetchTeams } from 'services/teams';
import { fetchPlayers } from 'services/players';

// Schemas
import {
  STRING_SCHEMA,
  STRING_REQUIRED_SCHEMA,
  OBJECT_REQUIRED_SCHEMA,
  BOOLEAN_SCHEMA,
} from 'schemas/base';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const PerformanceAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { state } = useLocation();
  const [currentUserTeam, setCurrentUserTeam] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState([]);
  const renderAfterCalled = useRef(false);
  const date = state ? state.date : null;

  useEffect(() => {
    setIsLoading(false);

    if (!date) {
      return;
    }

    fetchTeams().then((res) => setTeamOptions(res));
    fetchPlayers().then((res) => setPlayerOptions(res));
    fetchCurrentUserTeamData().then((res) => setCurrentUserTeam(res));

    if (!renderAfterCalled.current) {
      setTitleSEO(`Add Performance - ${date}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    team_id: currentUserTeam.team_id || '',
    date,
    is_sport_day: false,
    sport: '',
    lunch: '',
    snack: '',
    dinner: '',
  };
  const validationSchemaDict = {
    date: STRING_REQUIRED_SCHEMA,
    lunch: STRING_REQUIRED_SCHEMA,
    snack: STRING_REQUIRED_SCHEMA,
    dinner: STRING_REQUIRED_SCHEMA,
    is_sport_day: BOOLEAN_SCHEMA,
    sport: STRING_SCHEMA,
  };

  if (currentUserTeam && Object.keys(currentUserTeam).length > 0) {
    currentUserTeam.roster.forEach((player, index) => {
      const tempIndex = `player_${index + 1}`;

      initialValues[tempIndex] = player.player;
      initialValues[`${tempIndex}_sport`] = false;
      initialValues[`${tempIndex}_lunch`] = '';
      initialValues[`${tempIndex}_snack`] = '';
      initialValues[`${tempIndex}_dinner`] = '';
      initialValues[`${tempIndex}_lunch_info`] = '';
      initialValues[`${tempIndex}_snack_info`] = '';
      initialValues[`${tempIndex}_dinner_info`] = '';

      validationSchemaDict[tempIndex] = OBJECT_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_sport`] = BOOLEAN_SCHEMA;
      validationSchemaDict[`${tempIndex}_lunch`] = STRING_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_snack`] = STRING_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_dinner`] = STRING_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_lunch_info`] = STRING_SCHEMA;
      validationSchemaDict[`${tempIndex}_snack_info`] = STRING_SCHEMA;
      validationSchemaDict[`${tempIndex}_dinner_info`] = STRING_SCHEMA;
    });
  }

  const validationSchema = Yup.object().shape(validationSchemaDict);

  const fields1 = [
    {
      id: 'team_id',
      label: 'Team',
      typeField: 'select',
      options: teamOptions,
      disabled: true,
    },
    {
      id: 'date',
      label: 'Date',
      typeField: 'textfield',
      disabled: true,
    },
    {
      id: 'lunch',
      label: 'Lunch',
      typeField: 'textfield',
    },
    {
      id: 'snack',
      label: 'Snack',
      typeField: 'textfield',
    },
    {
      id: 'dinner',
      label: 'Dinner',
      typeField: 'textfield',
    },
    {
      id: 'is_sport_day',
      label: 'Is sport day?',
      typeField: 'checkbox',
    },
    {
      id: 'sport',
      label: 'Sport',
      typeField: 'textfield',
      dependsDisplayField: 'is_sport_day',
      dependsDisplayValue: true,
    },
  ];

  const fields2 = [
    {
      id: 'performance',
      label: 'Performance',
      typeField: 'performance',
      options: playerOptions,
    },
  ];

  return currentUserTeam && Object.keys(currentUserTeam).length > 0 ? (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields2Size={3}
      formikFn={addPerformanceReport}
      formikRedirect={performanceBasicURL}
      formikBtnText="Add report"
    />
  ) : null;
};

export default PerformanceAddView;
