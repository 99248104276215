// React
import React, { useContext, useState } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Constants
import { TOOLTIP_SLOTS } from 'constants/tooltip';
import { BLUE_MAIN, GRAY_MAIN, GREEN_MAIN, RED_MAIN, YELLOW_MAIN } from 'constants/color';

// Assets
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SportsIcon from '@mui/icons-material/Sports';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ParkIcon from '@mui/icons-material/Park';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SettingsIcon from '@mui/icons-material/Settings';

// Elements
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Modal,
  Backdrop,
} from '@mui/material';
import ButtonWithSubmenu from 'components/atoms/ButtonWithSubmenu/ButtonWithSubmenu';
import Matchups from 'components/molecules/Matchups/Matchups';
import VODsCompetitive from 'components/atoms/VODsCompetitive/VODsCompetitive';
import VODsScrim from 'components/atoms/VODsScrim/VODsScrim';
import VODsSoloQ from 'components/atoms/VODsSoloQ/VODsSoloQ';
import BasicData from 'components/atoms/BasicData/BasicData';

const DailyCard = ({ title, data }) => {
  const { user } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const columnSize = 9 / 10;

  const handleSetPlayer = (player) => {
    setCurrentPlayer(player);
  };

  const handleOpen = (tab, tabData) => {
    setOpen(true);

    let component = null;

    switch (tab) {
      case 'matchups':
        component = <Matchups data={tabData} />;
        break;
      case 'vods_competitive':
        component = <VODsCompetitive data={tabData} />;
        break;
      case 'vods_scrim':
        component = <VODsScrim data={tabData} />;
        break;
      case 'vods_soloq':
        component = <VODsSoloQ data={tabData} />;
        break;
      case 'meeting':
        component = <BasicData data={tabData} type="T" />;
        break;
      case 'team_activity':
        component = <BasicData data={tabData} type="N" />;
        break;
      case 'outside_of_game':
        component = <BasicData data={tabData} type="N" />;
        break;
      case 'draft_talk':
        component = <BasicData data={tabData} type="T" />;
        break;
      case 'content_obligations':
        component = <BasicData data={tabData} type="N" />;
        break;
      default:
        component = null;
        break;
    }

    setModalData(component);
  };
  const handleClose = () => {
    setOpen(false);
    setModalData(null);
  };

  return (
    <>
      <Box mb={1}>
        <Card>
          <CardContent>
            <Box display="flex" alignItems="center" mb={1}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="body2" component="p" color="text.secondary" mr={1}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="soloQ" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      S
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="1v1/2v2" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      M
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="VODs competitive" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      VC
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="VODs scrim" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      VS
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="VODs soloQ" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      VSQ
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="Meeting with coaches" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      MWC
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="Team activity" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      TA
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="Outside of game" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      OO
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="Draft talk" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      DT
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={columnSize}>
                  <Tooltip title="Content obligations" slotProps={TOOLTIP_SLOTS}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      textAlign="center"
                    >
                      CO
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
            {data
              ? Object.keys(data).map((player) => {
                  const {
                    id: playerId,
                    object: playerObject,
                    edit,
                    soloq,
                    matchups,
                    vods_competitive: vodsCompetitive,
                    vods_scrim: vodsScrim,
                    vods_soloq: vodsSoloq,
                    meeting,
                    team_activity: teamActivity,
                    outside_of_game: outsideOfGame,
                    draft_talk: draftTalk,
                    content_obligations: contentObligations,
                  } = data[player];

                  let tabNames = {
                    Matchups: { name: 'matchups', url: 'matchups', list: true },
                    'VODs competitive': {
                      name: 'vods_competitive',
                      url: 'vods-competitive',
                      list: true,
                    },
                    'VODs scrim': { name: 'vods_scrim', url: 'vods-scrim', list: true },
                    'VODs soloQ': { name: 'vods_soloq', url: 'vods-soloq', list: true },
                  };

                  if (user.is_editor || user.is_admin) {
                    tabNames = {
                      ...tabNames,
                      'Meeting with coaches': { name: 'meeting', url: 'meeting', list: false },
                      'Team activity': { name: 'team_activity', url: 'team-activity', list: false },
                      'Outside of game': {
                        name: 'outside_of_game',
                        url: 'outside-of-game',
                        list: false,
                      },
                      'Draft talk': { name: 'draft_talk', url: 'draft-talk', list: false },
                      'Content obligations': {
                        name: 'content_obligations',
                        url: 'content-obligations',
                        list: false,
                      },
                    };
                  }
                  const menuItems = [];

                  Object.keys(tabNames).forEach((tab) => {
                    const tabObj = tabNames[tab];
                    const tabName = tabObj.name;

                    if (playerObject.role === 'JNG' && tabName === 'matchups') {
                      return;
                    }

                    let actionTab = 'Add';
                    let link = '/daily/add';

                    if (
                      (tabObj.list && data[player][tabName] && data[player][tabName].length > 0) ||
                      (!tabObj.list && data[player][tabName])
                    ) {
                      actionTab = 'Edit';
                      link = `/daily/edit/${playerId}`;
                    }

                    link += `/${tabObj.url}`;

                    menuItems.push({
                      name: `${actionTab} ${tab}`,
                      link,
                      state: {
                        date: title,
                        player: playerObject,
                        name: tab,
                        dataName: tabName,
                        isList: tabObj.list,
                      },
                    });
                  });

                  return (
                    <Box key={player} display="flex" alignItems="center" minHeight={30}>
                      <Grid container alignItems="center">
                        <Grid item xs={2}>
                          <Typography variant="body2" component="p" mr={1}>
                            {player}
                          </Typography>
                        </Grid>
                        <Grid item xs={columnSize}>
                          <Typography variant="body2" component="p" textAlign="center">
                            {soloq}
                          </Typography>
                        </Grid>
                        <Grid item xs={columnSize}>
                          {matchups && matchups.length > 0 ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('matchups', matchups);
                                  handleSetPlayer(player);
                                }}
                              >
                                <EmojiEventsIcon fontSize="small" sx={{ color: YELLOW_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {vodsCompetitive && vodsCompetitive.length > 0 ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('vods_competitive', vodsCompetitive);
                                  handleSetPlayer(player);
                                }}
                              >
                                <LiveTvIcon fontSize="small" sx={{ color: RED_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {vodsScrim && vodsScrim.length > 0 ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('vods_scrim', vodsScrim);
                                  handleSetPlayer(player);
                                }}
                              >
                                <LiveTvIcon fontSize="small" sx={{ color: YELLOW_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {vodsSoloq && vodsSoloq.length > 0 ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('vods_soloq', vodsSoloq);
                                  handleSetPlayer(player);
                                }}
                              >
                                <LiveTvIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {meeting && meeting?.topic && meeting?.duration && meeting?.notes ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('meeting', meeting);
                                  handleSetPlayer(player);
                                }}
                              >
                                <SportsIcon fontSize="small" sx={{ color: BLUE_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {teamActivity &&
                          teamActivity?.name &&
                          teamActivity?.duration &&
                          teamActivity?.notes ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('team_activity', teamActivity);
                                  handleSetPlayer(player);
                                }}
                              >
                                <FitnessCenterIcon fontSize="small" sx={{ color: GRAY_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {outsideOfGame &&
                          outsideOfGame?.name &&
                          outsideOfGame?.duration &&
                          outsideOfGame?.notes ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('outside_of_game', outsideOfGame);
                                  handleSetPlayer(player);
                                }}
                              >
                                <ParkIcon fontSize="small" sx={{ color: GREEN_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {draftTalk &&
                          draftTalk?.topic &&
                          draftTalk?.duration &&
                          draftTalk?.notes ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('draft_talk', draftTalk);
                                  handleSetPlayer(player);
                                }}
                              >
                                <MeetingRoomIcon fontSize="small" sx={{ color: BLUE_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {contentObligations &&
                          contentObligations?.name &&
                          contentObligations?.duration &&
                          contentObligations?.notes ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <IconButton
                                size="small"
                                sx={{ p: 1 / 2 }}
                                onClick={() => {
                                  handleOpen('content_obligations', contentObligations);
                                  handleSetPlayer(player);
                                }}
                              >
                                <CameraAltIcon fontSize="small" sx={{ color: GRAY_MAIN }} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={columnSize}>
                          {edit ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <ButtonWithSubmenu
                                icon={<SettingsIcon fontSize="small" />}
                                size="small"
                                menu={menuItems}
                                player={playerObject}
                              />
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              : null}
          </CardContent>
        </Card>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '60%',
            bgcolor: 'background.paper',
          }}
        >
          <Box backgroundColor={BLUE_MAIN} textAlign="center" py={1 / 2}>
            <Typography variant="h6" textTransform="uppercase" fontWeight="700">
              {currentPlayer || ''}
            </Typography>
          </Box>
          {modalData}
        </Box>
      </Modal>
    </>
  );
};

DailyCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
};

DailyCard.defaultProps = {
  title: '',
};

export default DailyCard;
