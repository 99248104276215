// React
import React from 'react';

// Routes
import {
  homeURL,
  scrimsMatchesURL,
  scrimsDraftsURL,
  scrimsChampionPoolURL,
  scrimsPerformanceURL,
  scrimsBasicURL,
  dailyReportBasicURL,
  soloQLpByTeamReportBasicURL,
  soloQMatchesReportBasicURL,
  soloQChampionPoolReportBasicURL,
  soloQHoursReportBasicURL,
  soloQCountReportBasicURL,
  splitsSingleMatchesURL,
  splitsSingleChampionPoolURL,
  splitsSingleSynergiesURL,
  splitsSinglePicksURL,
  splitsSingleTeamsStatisticsURL,
  splitsSinglePlayersStatisticsURL,
  splitsSingleWinRateURL,
  splitsSingleSoloQURL,
  splitsSingleLpGainURL,
  splitsSingleBasicURL,
  splitsSingleURL,
  splitsBasicURL,
  teamsSingleMatchesURL,
  teamsSingleDraftsPatternsURL,
  teamsSingleDraftsURL,
  teamsSingleChampionPoolURL,
  teamsSingleFlexURL,
  teamsSingleSynergiesURL,
  teamsSinglePicksURL,
  teamsSingleEnemyDataURL,
  teamsSingleLvL1PositionsURL,
  teamsSingleLvL1WardsURL,
  teamsSingleJungleStatisticsURL,
  teamsSingleJungleURL,
  teamsSingleShopURL,
  teamsSingleWardsStatisticsURL,
  teamsSingleWardsSurvivedURL,
  teamsSingleWardsTimelineURL,
  teamsSingleWardsURL,
  teamsSingleOracleLensURL,
  teamsSingleHeatmapURL,
  teamsSinglePositionsOnMapURL,
  teamsSingleObjectivesURL,
  teamsSingleWardsObjectivesURL,
  teamsSingleSummonersURL,
  teamsSingleRecallsURL,
  // teamsSingleTPFlanksURL,
  teamsSingleGraphsURL,
  teamsSingleWinRateURL,
  teamsSinglePerformanceURL,
  // teamsSingleScoresURL,
  teamsSingleHoursURL,
  teamsSingleBasicURL,
  teamsSingleURL,
  teamsBasicURL,
  teamsSingleDraftsLeagupediaURL,
  teamsSingleDraftsPatternsLeaguepediaURL,
  teamsSingleChampionPoolLeaguepediaURL,
  teamsSingleFlexLeaguepediaURL,
  teamsSingleSynergiesLeaguepediaURL,
  teamsSinglePicksLeaguepediaURL,
  teamsSingleEnemyDataLeaguepediaURL,
  playersSingleChampionPoolURL,
  playersSingleStatisticsURL,
  playersSingleGraphsURL,
  playersSingleHoursURL,
  playersSingleBasicURL,
  playersSingleURL,
  playersBasicURL,
  gamesSingleURL,
  gamesBasicURL,
  gamesSingleScoreboardURL,
  gamesSingleTeamFightsURL,
  gamesSinglePositionsOnMapURL,
  searchVodBasicURL,
  searchCounterBasicURL,
  searchChampionsDataBasicURL,
  searchWorldsMatchupsBasicURL,
  comparePlayersBasicURL,
  replaysBasicURL,
  performanceBasicURL,
  libraryBasicURL,
  socialMediaH2HBasicURL,
  socialMediaBasicURL,
} from 'routes/app';
import {
  adminHomeURL,
  adminOptionsURL,
  adminUsersURL,
  adminTagsURL,
  adminChampionsURL,
  adminChampionSpellsURL,
  adminItemsURL,
  adminPatchesURL,
  adminSummonerSpellsURL,
  adminRunesURL,
  adminKeystonesURL,
  adminPerksURL,
  adminShardsURL,
  adminRegionsURL,
  adminServersURL,
  adminTiersURL,
  adminAccountsURL,
  adminRankedGamesURL,
  adminNationalitiesURL,
  adminLeaguesURL,
  adminSplitsURL,
  adminTeamsURL,
  adminPlayersURL,
  adminPlayerUrnsURL,
  adminMatchesURL,
  adminGamesURL,
  adminUnknownUrnsURL,
  adminGameErrorsURL,
} from 'routes/admin';

// Assets
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GroupIcon from '@mui/icons-material/Group';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PublicIcon from '@mui/icons-material/Public';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ErrorIcon from '@mui/icons-material/Error';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AssessmentIcon from '@mui/icons-material/Assessment';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

export const SCRIM_MENU = [
  {
    id: 1,
    title: 'Overview',
    link: scrimsBasicURL,
  },
  {
    id: 2,
    title: 'Matches',
    link: scrimsMatchesURL,
  },
  {
    id: 3,
    title: 'Drafts',
    link: scrimsDraftsURL,
  },
  {
    id: 4,
    title: 'Champion pool',
    link: scrimsChampionPoolURL,
  },
  {
    id: 5,
    title: 'Performance',
    link: scrimsPerformanceURL,
  },
];

export const SOLOQ_REPORT_MENU = [
  {
    id: 1,
    title: 'Count',
    link: soloQCountReportBasicURL,
  },
  {
    id: 2,
    title: 'Hours',
    link: soloQHoursReportBasicURL,
  },
  {
    id: 3,
    title: 'Champion pool',
    link: soloQChampionPoolReportBasicURL,
  },
  {
    id: 4,
    title: 'Matches',
    link: soloQMatchesReportBasicURL,
  },
  {
    id: 5,
    title: 'LP by Team',
    link: soloQLpByTeamReportBasicURL,
  },
];

export const SPLIT_MENU = [
  {
    id: 1,
    title: 'Overview',
    link: splitsSingleURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 2,
    title: 'Matches',
    link: splitsSingleMatchesURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 3,
    title: 'Champion pool',
    link: splitsSingleChampionPoolURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 4,
    title: 'Synergies',
    link: splitsSingleSynergiesURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 5,
    title: 'Picks',
    link: splitsSinglePicksURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 6,
    title: 'Teams statistics',
    link: splitsSingleTeamsStatisticsURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 7,
    title: 'Players statistics',
    link: splitsSinglePlayersStatisticsURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 8,
    title: 'Win Rate',
    link: splitsSingleWinRateURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 9,
    title: 'SoloQ',
    link: splitsSingleSoloQURL,
    basic: splitsSingleBasicURL,
  },
  {
    id: 10,
    title: 'LP Gain',
    link: splitsSingleLpGainURL,
    basic: splitsSingleBasicURL,
  },
];

export const TEAM_MENU = [
  {
    id: 1,
    title: 'Overview',
    link: teamsSingleURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 2,
    title: 'Matches',
    link: teamsSingleMatchesURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 3,
    title: 'Drafts',
    link: teamsSingleDraftsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 4,
    title: 'Drafts patterns',
    link: teamsSingleDraftsPatternsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 5,
    title: 'Champion pool',
    link: teamsSingleChampionPoolURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 6,
    title: 'Flex',
    link: teamsSingleFlexURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 7,
    title: 'Synergies',
    link: teamsSingleSynergiesURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 8,
    title: 'Picks',
    link: teamsSinglePicksURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 9,
    title: 'Enemy Data',
    link: teamsSingleEnemyDataURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 10,
    title: 'LvL 1 Positions',
    link: teamsSingleLvL1PositionsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 11,
    title: 'LvL 1 Wards',
    link: teamsSingleLvL1WardsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 12,
    title: 'Jungle',
    link: teamsSingleJungleURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 13,
    title: 'Jungle Statistics',
    link: teamsSingleJungleStatisticsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 14,
    title: 'Shop',
    link: teamsSingleShopURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 15,
    title: 'Wards',
    link: teamsSingleWardsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 16,
    title: 'Wards Timeline',
    link: teamsSingleWardsTimelineURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 17,
    title: 'Wards Survived',
    link: teamsSingleWardsSurvivedURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 18,
    title: 'Wards Statistics',
    link: teamsSingleWardsStatisticsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 19,
    title: 'Wards Objectives',
    link: teamsSingleWardsObjectivesURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 20,
    title: 'Oracle Lens',
    link: teamsSingleOracleLensURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 21,
    title: 'Heatmap',
    link: teamsSingleHeatmapURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 22,
    title: 'Positions on map',
    link: teamsSinglePositionsOnMapURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 23,
    title: 'Objectives',
    link: teamsSingleObjectivesURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 24,
    title: 'Summoners',
    link: teamsSingleSummonersURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 25,
    title: 'Recalls',
    link: teamsSingleRecallsURL,
    basic: teamsSingleBasicURL,
  },
  // {
  //   id: 26,
  //   title: 'TP flanks',
  //   link: teamsSingleTPFlanksURL,
  //   basic: teamsSingleBasicURL,
  // },
  {
    id: 95,
    title: 'Graphs',
    link: teamsSingleGraphsURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 96,
    title: 'Win rate',
    link: teamsSingleWinRateURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 97,
    title: 'Performance',
    link: teamsSinglePerformanceURL,
    basic: teamsSingleBasicURL,
  },
  // {
  //   id: 98,
  //   title: 'Scores',
  //   link: teamsSingleScoresURL,
  //   basic: teamsSingleBasicURL,
  // },
  {
    id: 99,
    title: 'Hours',
    link: teamsSingleHoursURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 999,
    title: 'Leaguepedia',
    link: teamsSingleDraftsLeagupediaURL,
    basic: teamsSingleBasicURL,
  },
];

export const TEAM_LEAGUEPEDIA_MENU = [
  {
    id: 1,
    title: 'Database data',
    link: teamsSingleURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 2,
    title: 'Drafts',
    link: teamsSingleDraftsLeagupediaURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 3,
    title: 'Drafts patterns',
    link: teamsSingleDraftsPatternsLeaguepediaURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 4,
    title: 'Champion pool',
    link: teamsSingleChampionPoolLeaguepediaURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 5,
    title: 'Flex',
    link: teamsSingleFlexLeaguepediaURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 6,
    title: 'Synergies',
    link: teamsSingleSynergiesLeaguepediaURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 7,
    title: 'Picks',
    link: teamsSinglePicksLeaguepediaURL,
    basic: teamsSingleBasicURL,
  },
  {
    id: 8,
    title: 'Enemy Data',
    link: teamsSingleEnemyDataLeaguepediaURL,
    basic: teamsSingleBasicURL,
  },
];

export const PLAYER_MENU = [
  {
    id: 1,
    title: 'Overview',
    link: playersSingleURL,
    basic: playersSingleBasicURL,
  },
  {
    id: 2,
    title: 'Champion pool',
    link: playersSingleChampionPoolURL,
    basic: playersSingleBasicURL,
  },
  {
    id: 3,
    title: 'Statistics',
    link: playersSingleStatisticsURL,
    basic: playersSingleBasicURL,
  },
  {
    id: 4,
    title: 'Graphs',
    link: playersSingleGraphsURL,
    basic: playersSingleBasicURL,
  },
  {
    id: 99,
    title: 'Hours',
    link: playersSingleHoursURL,
    basic: playersSingleBasicURL,
  },
];

export const USER_MENU = (teamAccess) => {
  let menu = [
    {
      id: 1,
      title: 'Home',
      icon: <HomeIcon fontSize="small" />,
      link: homeURL,
    },
  ];

  if (teamAccess) {
    menu = [
      ...menu,
      ...[
        {
          id: 2,
          title: 'Scrims',
          icon: <EventIcon fontSize="small" />,
          items: SCRIM_MENU,
        },
        {
          id: 3,
          title: 'Daily Report',
          icon: <CalendarMonthIcon fontSize="small" />,
          link: dailyReportBasicURL,
        },
        {
          id: 4,
          title: 'SoloQ Report',
          icon: <AssessmentIcon fontSize="small" />,
          items: SOLOQ_REPORT_MENU,
        },
      ],
    ];
  }

  menu = [
    ...menu,
    ...[
      {
        id: 5,
        title: 'Splits',
        icon: <ListAltIcon fontSize="small" />,
        link: splitsBasicURL,
      },
      {
        id: 6,
        title: 'Teams',
        icon: <GroupsIcon fontSize="small" />,
        link: teamsBasicURL,
      },
      {
        id: 7,
        title: 'Players',
        icon: <FolderSharedIcon fontSize="small" />,
        link: playersBasicURL,
      },
      {
        id: 8,
        title: 'Search',
        icon: <YouTubeIcon fontSize="small" />,
        items: [
          {
            id: 1,
            title: 'VOD',
            link: searchVodBasicURL,
          },
          {
            id: 2,
            title: 'Counter',
            link: searchCounterBasicURL,
          },
          {
            id: 3,
            title: 'Champions data',
            link: searchChampionsDataBasicURL,
          },
          {
            id: 4,
            title: 'Worlds matchups',
            link: searchWorldsMatchupsBasicURL,
          },
        ],
      },
      {
        id: 9,
        title: 'Compare',
        icon: <CompareArrowsIcon fontSize="small" />,
        items: [
          {
            id: 1,
            title: 'Players',
            link: comparePlayersBasicURL,
          },
        ],
      },
      {
        id: 10,
        title: 'Replays',
        icon: <OndemandVideoIcon fontSize="small" />,
        link: replaysBasicURL,
      },
      {
        id: 11,
        title: 'Performance',
        icon: <MenuBookIcon fontSize="small" />,
        link: performanceBasicURL,
      },
      {
        id: 12,
        title: 'Library',
        icon: <VideoLibraryIcon fontSize="small" />,
        link: libraryBasicURL,
      },
    ],
  ];

  return menu;
};

export const ADMIN_MENU = [
  {
    id: 1,
    title: 'Home',
    icon: <HomeIcon fontSize="small" />,
    link: adminHomeURL,
  },
  {
    id: 2,
    title: 'Options',
    icon: <SettingsIcon fontSize="small" />,
    link: adminOptionsURL,
  },
  {
    id: 3,
    title: 'Users',
    icon: <GroupIcon fontSize="small" />,
    link: adminUsersURL,
  },
  {
    id: 4,
    title: 'Data',
    icon: <FormatListBulletedIcon fontSize="small" />,
    items: [
      {
        id: 1,
        title: 'Tags',
        link: adminTagsURL,
      },
      {
        id: 2,
        title: 'Champions',
        link: adminChampionsURL,
      },
      {
        id: 3,
        title: 'Champion Spells',
        link: adminChampionSpellsURL,
      },
      {
        id: 4,
        title: 'Items',
        link: adminItemsURL,
      },
      {
        id: 5,
        title: 'Patches',
        link: adminPatchesURL,
      },
    ],
  },
  {
    id: 5,
    title: 'Static data',
    icon: <MenuBookIcon fontSize="small" />,
    items: [
      {
        id: 1,
        title: 'Summoner Spells',
        link: adminSummonerSpellsURL,
      },
      {
        id: 2,
        title: 'Runes',
        link: adminRunesURL,
      },
      {
        id: 3,
        title: 'Keystones',
        link: adminKeystonesURL,
      },
      {
        id: 4,
        title: 'Perks',
        link: adminPerksURL,
      },
      {
        id: 5,
        title: 'Shards',
        link: adminShardsURL,
      },
    ],
  },
  {
    id: 6,
    title: 'Riot API',
    icon: <PublicIcon fontSize="small" />,
    items: [
      {
        id: 1,
        title: 'Regions',
        link: adminRegionsURL,
      },
      {
        id: 2,
        title: 'Servers',
        link: adminServersURL,
      },
      {
        id: 3,
        title: 'Tiers',
        link: adminTiersURL,
      },
      {
        id: 4,
        title: 'Accounts',
        link: adminAccountsURL,
      },
      {
        id: 5,
        title: 'Ranked games',
        link: adminRankedGamesURL,
      },
    ],
  },
  {
    id: 7,
    title: 'Database',
    icon: <SportsEsportsIcon fontSize="small" />,
    items: [
      {
        id: 1,
        title: 'Nationalities',
        link: adminNationalitiesURL,
      },
      {
        id: 2,
        title: 'Leagues',
        link: adminLeaguesURL,
      },
      {
        id: 3,
        title: 'Splits',
        link: adminSplitsURL,
      },
      {
        id: 4,
        title: 'Teams',
        link: adminTeamsURL,
      },
      {
        id: 5,
        title: 'Players',
        link: adminPlayersURL,
      },
      {
        id: 6,
        title: 'Player Urns',
        link: adminPlayerUrnsURL,
      },
      {
        id: 7,
        title: 'Matches',
        link: adminMatchesURL,
      },
      {
        id: 8,
        title: 'Games',
        link: adminGamesURL,
      },
    ],
  },
  {
    id: 8,
    title: 'Errors',
    icon: <ErrorIcon fontSize="small" />,
    items: [
      {
        id: 1,
        title: 'Urns',
        link: adminUnknownUrnsURL,
      },
      {
        id: 2,
        title: 'Games',
        link: adminGameErrorsURL,
      },
    ],
  },
];

export const GAME_MENU = [
  {
    id: 1,
    title: 'Overview',
    link: gamesSingleURL,
    basic: gamesBasicURL,
  },
  {
    id: 2,
    title: 'Scoreboard',
    link: gamesSingleScoreboardURL,
    basic: gamesBasicURL,
  },
  {
    id: 3,
    title: 'Positions on map',
    link: gamesSinglePositionsOnMapURL,
    basic: gamesBasicURL,
  },
  {
    id: 4,
    title: 'Team Fights',
    link: gamesSingleTeamFightsURL,
    basic: gamesBasicURL,
  },
];

export const SOCIAL_MEDIA_MENU = [
  {
    id: 1,
    title: 'Post game',
    link: socialMediaBasicURL,
  },
  {
    id: 2,
    title: 'H2H',
    link: socialMediaH2HBasicURL,
  },
];
