// Constants
import { SIDES as SIDES_LIST } from 'constants/side';
import { ROLES as ROLES_LIST, SYNERGIES as SYNERGIES_LIST } from 'constants/role';
import { MATCH_TYPES_LIST_ALL, MATCH_TYPES_S_LIST, MATCH_TYPES_LIST } from 'constants/match';
import {
  PLATES,
  PLATES_PER_ROLE,
  HERALDS,
  DRAGONS,
  BARONS,
  TOWERS,
  DRAGON_1,
  DRAGON_2,
  DRAGON_3,
  DRAGON_4,
  HERALD_1,
  BARON_1,
  BARON_2,
} from 'constants/objective';
import { BATTLE, DIFFERENCE, GENERAL, OBJECTIVES, VISION } from 'constants/statistic';

export const USER = {
  id: 'user',
  label: 'User',
  filterType: 'select',
  options: [
    {
      id: 'all',
      label: 'ALL',
      value: 'ALL',
    },
  ],
};

export const MATCH_TYPES_ALL = {
  id: 'matchTypeAll',
  label: 'Game type',
  filterType: 'select',
  options: MATCH_TYPES_LIST_ALL,
  minWidth: 175,
};

export const MATCH_TYPES_S = {
  id: 'matchTypeS',
  label: 'Game type',
  filterType: 'select',
  options: MATCH_TYPES_S_LIST,
  minWidth: 175,
};

export const MATCH_TYPES = {
  id: 'matchType',
  label: 'Game type',
  filterType: 'select',
  options: MATCH_TYPES_LIST,
  minWidth: 175,
};

export const RANGE_DATE = {
  id: 'rangeDate',
  filterType: 'rangeDate',
};

export const SOLOQ_RANGE_DATE = {
  id: 'soloQRangeDate',
  filterType: 'rangeDate',
};

export const RESULTS = {
  id: 'result',
  label: 'Result',
  filterType: 'select',
  options: [
    {
      id: 'all',
      label: 'BOTH',
      value: 'ALL',
    },
    {
      id: 'win',
      label: 'WIN',
      value: 'WIN',
    },
    {
      id: 'loss',
      label: 'LOSE',
      value: 'LOSE',
    },
  ],
};

export const SIDES = {
  id: 'side',
  label: 'Side',
  filterType: 'select',
  options: [
    {
      id: 'all',
      label: 'BOTH',
      value: 'ALL',
    },
    ...SIDES_LIST,
  ],
};

export const PATCHES = {
  id: 'patch',
  label: 'Patch',
  filterType: 'select',
  options: [
    {
      id: 'all',
      label: 'ALL',
      value: 'ALL',
    },
  ],
};

export const OPPONENTS = {
  id: 'opponent',
  label: 'Opponent',
  filterType: 'select',
  options: [
    {
      id: 'all',
      label: 'ALL',
      value: 'ALL',
    },
  ],
};

export const ROLES_ALL = {
  id: 'roleAll',
  label: 'Role',
  filterType: 'select',
  options: [
    {
      id: 'all',
      label: 'ALL',
      value: 'ALL',
    },
    ...ROLES_LIST,
  ],
};

export const ROLES = {
  id: 'role',
  label: 'Role',
  filterType: 'select',
  options: ROLES_LIST,
};

export const MULTI_ROLES = {
  id: 'multirole',
  label: 'Role',
  filterType: 'multiselect',
  options: ROLES_LIST,
};

export const MULTI_TEAMS = {
  id: 'teams',
  label: 'Teams',
  filterType: 'multiselect',
  options: [],
};

export const MULTI_CHAMPIONS = {
  id: 'champions',
  label: 'Champions',
  filterType: 'multiselect',
  options: [],
};

export const MULTI_TAGS = {
  id: 'tags',
  label: 'Tags',
  filterType: 'multiselect',
  options: [],
};

export const SYNERGIES = {
  id: 'synergy',
  label: 'Synergy',
  filterType: 'select',
  options: SYNERGIES_LIST,
};

export const PICKS = {
  id: 'pick',
  label: 'Type',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: 'BOTH',
      value: 'BOTH',
    },
    {
      id: 2,
      label: 'BLIND',
      value: 'BLIND',
    },
    {
      id: 3,
      label: 'COUNTER',
      value: 'COUNTER',
    },
  ],
};

export const ENEMY = {
  id: 'enemy',
  label: 'Type',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: 'PICKS',
      value: 'PICKS',
    },
    {
      id: 2,
      label: 'BANS',
      value: 'BANS',
    },
  ],
};

export const CURRENT_TIME = {
  id: 'currentTime',
  label: 'Time',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: '00:30',
      value: 30,
    },
    {
      id: 2,
      label: '00:45',
      value: 45,
    },
    {
      id: 3,
      label: '01:00',
      value: 60,
    },
    {
      id: 4,
      label: '01:15',
      value: 75,
    },
    {
      id: 5,
      label: '01:30',
      value: 90,
    },
    {
      id: 6,
      label: '01:45',
      value: 105,
    },
    {
      id: 7,
      label: '02:00',
      value: 120,
    },
  ],
};

export const WARD_TYPE = {
  id: 'wardType',
  label: 'Wards',
  filterType: 'select',
  options: [
    {
      id: 'all',
      label: 'ALL',
      value: 'ALL',
    },
    {
      id: 'NORMAL',
      label: 'NORMAL',
      value: 'NORMAL',
    },
    {
      id: 'CONTROL',
      label: 'CONTROL',
      value: 'CONTROL',
    },
    {
      id: 'BLUE',
      label: 'BLUE',
      value: 'BLUE',
    },
  ],
};

export const PATH_TYPE = {
  id: 'pathType',
  label: 'Path',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: '1st',
      value: 1,
    },
    {
      id: 2,
      label: '2nd',
      value: 2,
    },
  ],
};

export const GRAPH_TEAM_TYPE = {
  id: 'graphTeamType',
  label: 'Graph',
  filterType: 'select',
  minWidth: 225,
  options: [
    {
      id: 1,
      label: 'GOLD DIFF',
      value: 'GOLD_DIFF',
    },
    {
      id: 2,
      label: 'CS DIFF',
      value: 'CS_DIFF',
    },
    {
      id: 3,
      label: 'XP DIFF',
      value: 'XP_DIFF',
    },
    {
      id: 4,
      label: 'DMG DIFF',
      value: 'DMG_DIFF',
    },
    {
      id: 5,
      label: 'GOLD CURRENT DIFF',
      value: 'GOLD_CURRENT_DIFF',
    },
    {
      id: 6,
      label: 'WARDS PLACED DIFF',
      value: 'WARDS_PLACED_DIFF',
    },
    {
      id: 7,
      label: 'WARDS KILLED DIFF',
      value: 'WARDS_KILLED_DIFF',
    },
    {
      id: 8,
      label: 'JUNGLE CAMPS',
      value: 'JUNGLE_CAMPS',
    },
    {
      id: 9,
      label: 'WIN RATE GD@7',
      value: 'WIN_RATE_@420',
    },
    {
      id: 10,
      label: 'WIN RATE GD@14',
      value: 'WIN_RATE_@840',
    },
    {
      id: 11,
      label: 'WIN RATE GD@20',
      value: 'WIN_RATE_@1200',
    },
  ],
};

export const GRAPH_PLAYER_TYPE = {
  id: 'graphPlayerType',
  label: 'Graph',
  filterType: 'select',
  minWidth: 200,
  options: [
    {
      id: 1,
      label: 'GOLD',
      value: 'GOLD',
    },
    {
      id: 2,
      label: 'CS',
      value: 'CS',
    },
    {
      id: 3,
      label: 'XP',
      value: 'XP',
    },
    {
      id: 4,
      label: 'DMG',
      value: 'DMG',
    },
    {
      id: 5,
      label: 'GOLD CURRENT',
      value: 'GOLD_CURRENT',
    },
    {
      id: 6,
      label: 'WARDS PLACED',
      value: 'WARDS_PLACED',
    },
  ],
};

export const GRAPH_DATA_PLAYER_TYPE = {
  id: 'graphDataPlayerType',
  label: 'Data',
  filterType: 'select',
  minWidth: 150,
  options: [
    {
      id: 1,
      label: 'VALUE',
      value: 'VALUE',
    },
    {
      id: 2,
      label: 'DIFF',
      value: 'DIFF',
    },
    {
      id: 3,
      label: 'SWING',
      value: 'SWING',
    },
  ],
};

export const TIME_RANGE_TYPE = {
  id: 'timerange',
  label: 'Time range',
  filterType: 'timerange',
  value: [0, 420],
};

export const OBJECTIVE_TYPE = {
  id: 'objective',
  label: 'Objective',
  filterType: 'select',
  minWidth: 225,
  options: [
    {
      id: 1,
      label: PLATES,
      value: PLATES,
    },
    {
      id: 2,
      label: PLATES_PER_ROLE,
      value: PLATES_PER_ROLE,
    },
    {
      id: 3,
      label: HERALDS,
      value: HERALDS,
    },
    {
      id: 4,
      label: DRAGONS,
      value: DRAGONS,
    },
    {
      id: 5,
      label: BARONS,
      value: BARONS,
    },
    {
      id: 6,
      label: TOWERS,
      value: TOWERS,
    },
  ],
};

export const WARD_OBJECTIVE_TYPE = {
  id: 'wardObjective',
  label: 'Objective',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: DRAGON_1,
      value: DRAGON_1,
    },
    {
      id: 2,
      label: DRAGON_2,
      value: DRAGON_2,
    },
    {
      id: 3,
      label: DRAGON_3,
      value: DRAGON_3,
    },
    {
      id: 4,
      label: DRAGON_4,
      value: DRAGON_4,
    },
    {
      id: 5,
      label: HERALD_1,
      value: HERALD_1,
    },
    {
      id: 6,
      label: BARON_1,
      value: BARON_1,
    },
    {
      id: 7,
      label: BARON_2,
      value: BARON_2,
    },
  ],
};

export const TEAM_OBJECTIVE_TYPE = {
  id: 'teamObjective',
  label: 'Type',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: 'ALL',
      value: 'ALL',
    },
    {
      id: 2,
      label: 'TOOK',
      value: 'TOOK',
    },
    {
      id: 3,
      label: 'LOST',
      value: 'LOST',
    },
  ],
};

export const STATISTIC_TEAM_TYPE = {
  id: 'statisticTeam',
  label: 'Statistic',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: GENERAL,
      value: GENERAL,
    },
    {
      id: 2,
      label: BATTLE,
      value: BATTLE,
    },
    {
      id: 3,
      label: VISION,
      value: VISION,
    },
    {
      id: 4,
      label: OBJECTIVES,
      value: OBJECTIVES,
    },
    {
      id: 5,
      label: DIFFERENCE,
      value: DIFFERENCE,
    },
  ],
};

export const SUMMONER_TYPE = {
  id: 'summoner',
  label: 'Summoner',
  filterType: 'select',
  options: [
    {
      id: 1,
      label: 'Teleport',
      value: 'SummonerTeleport',
    },
    {
      id: 2,
      label: 'Flash',
      value: 'SummonerFlash',
    },
  ],
};

export const PLAYER_MAIN_ROLE_TYPE = {
  id: 'playerMainRole',
  label: 'Only main role',
  filterType: 'checkbox',
};

export const ATE_TYPE = {
  id: 'ate',
  label: 'Ate lunch',
  filterType: 'checkbox',
};

export const SPORT_TYPE = {
  id: 'sport',
  label: 'Do sport',
  filterType: 'checkbox',
};

export const SNACK_TYPE = {
  id: 'snack',
  label: 'Ate snack',
  filterType: 'checkbox',
};

export const BLITZ_TYPE = {
  id: 'blitz',
  label: 'Hide blitz',
  filterType: 'checkbox',
};

export const DRAFT_FILTER = {
  id: 'draftFilter',
  label: 'Draft',
  filterType: 'draft',
};
