// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import MatchupsManageData from 'components/molecules/MatchupsManageData/MatchupsManageData';
import VODsCompetitiveManageData from 'components/molecules/VODsCompetitiveManageData/VODsCompetitiveManageData';
import VODsScrimManageData from 'components/molecules/VODsScrimManageData/VODsScrimManageData';
import VODsSoloQManageData from 'components/molecules/VODsSoloQManageData/VODsSoloQManageData';
import BasicManageData from 'components/molecules/BasicManageData/BasicManageData';

// Component
const DailyManageData = ({ value, loadData, handleChange, setFieldValue }) => {
  const tabName = loadData.tab;
  let component = null;

  switch (tabName) {
    case 'matchups':
      component = (
        <MatchupsManageData
          value={value}
          loadData={loadData}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      );
      break;
    case 'vods_competitive':
      component = (
        <VODsCompetitiveManageData
          value={value}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      );
      break;
    case 'vods_scrim':
      component = (
        <VODsScrimManageData
          value={value}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      );
      break;
    case 'vods_soloq':
      component = (
        <VODsSoloQManageData
          value={value}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      );
      break;
    case 'meeting':
      component = (
        <BasicManageData
          label="Meeting with coaches"
          fields={[
            { name: 'topic', label: 'Topic' },
            { name: 'duration', label: 'Duration' },
          ]}
          value={value}
          handleChange={handleChange}
        />
      );
      break;
    case 'team_activity':
      component = (
        <BasicManageData
          label="Team activity"
          fields={[
            { name: 'name', label: 'Name' },
            { name: 'duration', label: 'Duration' },
          ]}
          value={value}
          handleChange={handleChange}
        />
      );
      break;
    case 'outside_of_game':
      component = (
        <BasicManageData
          label="Outside of game"
          fields={[
            { name: 'name', label: 'Name' },
            { name: 'duration', label: 'Duration' },
          ]}
          value={value}
          handleChange={handleChange}
        />
      );
      break;
    case 'draft_talk':
      component = (
        <BasicManageData
          label="Draft talk"
          fields={[
            { name: 'topic', label: 'Topic' },
            { name: 'duration', label: 'Duration' },
          ]}
          value={value}
          handleChange={handleChange}
        />
      );
      break;
    case 'content_obligations':
      component = (
        <BasicManageData
          label="Content obligations"
          fields={[
            {
              name: 'name',
              label: 'Name',
            },
            {
              name: 'duration',
              label: 'Duration',
            },
          ]}
          value={value}
          handleChange={handleChange}
        />
      );
      break;
    default:
      component = null;
      break;
  }

  return component;
};

DailyManageData.propTypes = {
  value: PropTypes.any.isRequired,
  loadData: PropTypes.shape({
    players: PropTypes.arrayOf(PropTypes.object),
    champions: PropTypes.arrayOf(PropTypes.object),
    role: PropTypes.string,
    tab: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

DailyManageData.defaultProps = {
  loadData: {
    players: [],
    champions: [],
    role: '',
    tab: '',
  },
};

export default DailyManageData;
