// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminSplitsURL } from 'routes/admin';

// Services
import { fetchLeagues } from 'services/leagues';
import { fetchTeams } from 'services/teams';
import { addSplit } from 'services/splits';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminSplitsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchLeagues().then((res) => setLeagueOptions(res));

    fetchTeams().then((res) => setTeamOptions(res));

    setIsLoading(false);
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Splits ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    league: null,
    provider_id: '',
    start_date: '',
    end_date: '',
    leaguepedia: '',
    teams: [],
    visible: true,
    get_data: false,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    league: Yup.object().typeError('Choose league!').required('Required'),
    provider_id: Yup.string(),
    start_date: Yup.string(),
    end_date: Yup.string(),
    leaguepedia: Yup.string().required('Required'),
    teams: Yup.array(),
    visible: Yup.boolean(),
    get_data: Yup.boolean(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'provider_id',
      label: 'Provider ID',
      typeField: 'textfield',
    },
    {
      id: 'leaguepedia',
      label: 'Leaguepedia',
      typeField: 'textfield',
    },
    {
      id: 'visible',
      label: 'Visible',
      typeField: 'checkbox',
    },
  ];
  const fields2 = [
    {
      id: 'league',
      label: 'League',
      typeField: 'autocomplete',
      options: leagueOptions,
    },
    {
      id: 'start_date',
      label: 'Start',
      typeField: 'textfield',
      dependsDisableField: 'get_data',
      dependsDisableValue: true,
    },
    {
      id: 'end_date',
      label: 'End',
      typeField: 'textfield',
      dependsDisableField: 'get_data',
      dependsDisableValue: true,
    },
    {
      id: 'teams',
      label: 'Teams',
      typeField: 'multiautocomplete',
      options: teamOptions,
      dependsDisableField: 'get_data',
      dependsDisableValue: true,
    },
    {
      id: 'get_data',
      label: 'Get data',
      typeField: 'checkbox',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFn={addSplit}
      formikRedirect={adminSplitsURL}
      formikBtnText="Add split"
    />
  );
};

export default AdminSplitsAddView;
