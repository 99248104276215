// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminAccountsURL } from 'routes/admin';

// Services
import { fetchServers } from 'services/servers';
import { addAccount } from 'services/accounts';
import { fetchPlayers } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminAccountsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [serverOptions, setServerOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchServers().then((res) => setServerOptions(res));
    fetchPlayers().then((res) => setPlayerOptions(res));

    setIsLoading(false);
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Accounts ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    tag: '',
    server: null,
    hidden: false,
    player: null,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    tag: Yup.string().required('Required'),
    server: Yup.object().typeError('Choose server!').required('Required'),
    hidden: Yup.boolean(),
    player: Yup.object().nullable(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'tag',
      label: 'Tag',
      typeField: 'textfield',
    },
    {
      id: 'hidden',
      label: 'Hidden',
      typeField: 'checkbox',
    },
  ];
  const fields2 = [
    {
      id: 'server',
      label: 'Server',
      typeField: 'autocomplete',
      options: serverOptions,
    },
    {
      id: 'player',
      label: 'Player',
      typeField: 'autocomplete',
      options: playerOptions,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFn={addAccount}
      formikRedirect={adminAccountsURL}
      formikBtnText="Add account"
    />
  );
};

export default AdminAccountsAddView;
