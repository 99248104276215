// React
import React, { useContext, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminLeaguesURL } from 'routes/admin';

// Services
import { addLeague } from 'services/leagues';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminLeaguesAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    setIsLoading(false);

    if (!renderAfterCalled.current) {
      setTitleSEO(`Add - Leagues ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });
  const fields = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields}
      formikFn={addLeague}
      formikRedirect={adminLeaguesURL}
      formikBtnText="Add league"
    />
  );
};

export default AdminLeaguesAddView;
