// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { performanceBasicURL } from 'routes/app';

// Services
import { fetchSinglePerformanceReport, updatePerformanceReport } from 'services/performance';
import { fetchPlayers } from 'services/players';

// Schemas
import {
  STRING_SCHEMA,
  STRING_REQUIRED_SCHEMA,
  OBJECT_REQUIRED_SCHEMA,
  BOOLEAN_SCHEMA,
} from 'schemas/base';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const PerformanceEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [playerOptions, setPlayerOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchPlayers().then((res) => setPlayerOptions(res));
    fetchSinglePerformanceReport(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit Performance - ${data?.date}`);
    }
  }, [data]);

  const initialValues = {
    team_id: data?.team_id || '',
    date: data?.date || '',
    is_sport_day: data?.data?.is_sport_day || false,
    sport: data?.data?.sport || '',
    lunch: data?.data?.lunch || '',
    snack: data?.data?.snack || '',
    dinner: data?.data?.dinner || '',
  };
  const validationSchemaDict = {
    date: STRING_REQUIRED_SCHEMA,
    lunch: STRING_REQUIRED_SCHEMA,
    snack: STRING_REQUIRED_SCHEMA,
    dinner: STRING_REQUIRED_SCHEMA,
    is_sport_day: BOOLEAN_SCHEMA,
    sport: STRING_SCHEMA,
  };

  if (
    data &&
    Object.keys(data).length > 0 &&
    data.data &&
    Object.keys(data.data).length > 0 &&
    data.data.players &&
    Object.keys(data.data.players).length > 0
  ) {
    const dict = data.data.players;
    const items = Object.keys(dict).map((key) => [key, dict[key]]);

    items.sort((first, second) => first[1].order - second[1].order);

    const keys = items.map((e) => e[0]);

    keys.forEach((player, index) => {
      const tempIndex = `player_${index + 1}`;
      const playerData = data.data.players[player];
      const playerOption = playerOptions.find((option) => option.id === playerData.id);

      initialValues[tempIndex] = playerOption || null;
      initialValues[`${tempIndex}_sport`] = playerData?.sport || false;
      initialValues[`${tempIndex}_lunch`] = playerData?.lunch || '';
      initialValues[`${tempIndex}_snack`] = playerData?.snack || '';
      initialValues[`${tempIndex}_dinner`] = playerData?.dinner || '';
      initialValues[`${tempIndex}_lunch_info`] = playerData?.lunch_info || '';
      initialValues[`${tempIndex}_snack_info`] = playerData?.snack_info || '';
      initialValues[`${tempIndex}_dinner_info`] = playerData?.dinner_info || '';

      validationSchemaDict[tempIndex] = OBJECT_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_sport`] = BOOLEAN_SCHEMA;
      validationSchemaDict[`${tempIndex}_lunch`] = STRING_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_snack`] = STRING_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_dinner`] = STRING_REQUIRED_SCHEMA;
      validationSchemaDict[`${tempIndex}_lunch_info`] = STRING_SCHEMA;
      validationSchemaDict[`${tempIndex}_snack_info`] = STRING_SCHEMA;
      validationSchemaDict[`${tempIndex}_dinner_info`] = STRING_SCHEMA;
    });
  }

  const validationSchema = Yup.object().shape(validationSchemaDict);

  const fields1 = [
    {
      id: 'team_id',
      label: 'Team',
      typeField: 'textfield',
      disabled: true,
    },
    {
      id: 'date',
      label: 'Date',
      typeField: 'textfield',
      disabled: true,
    },
    {
      id: 'lunch',
      label: 'Lunch',
      typeField: 'textfield',
    },
    {
      id: 'snack',
      label: 'Snack',
      typeField: 'textfield',
    },
    {
      id: 'dinner',
      label: 'Dinner',
      typeField: 'textfield',
    },
    {
      id: 'is_sport_day',
      label: 'Is sport day?',
      typeField: 'checkbox',
    },
    {
      id: 'sport',
      label: 'Sport',
      typeField: 'textfield',
      dependsDisplayField: 'is_sport_day',
      dependsDisplayValue: true,
    },
  ];

  const fields2 = [
    {
      id: 'performance',
      label: 'Performance',
      typeField: 'performance',
      options: playerOptions,
    },
  ];

  return data && Object.keys(data).length > 0 ? (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields2Size={3}
      formikFn={updatePerformanceReport}
      formikAdditionalFnArgs={[id]}
      formikRedirect={performanceBasicURL}
      formikBtnText="Update report"
    />
  ) : null;
};

export default PerformanceEditView;
