// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Routes
import { libraryAddURL, libraryBaseURL } from 'routes/app';

// Services
import { fetchLibrary, deleteLibrary } from 'services/library';

// Constants
import { LIBRARY_GAMES_LIST_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';

// Elements
import { Box } from '@mui/material';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import AdminList from 'components/molecules/AdminList/AdminList';

const LibraryView = () => {
  const {
    setTitleSEO,
    filters: {
      library: {
        user,
        user_data,
        matchType,
        side,
        teams,
        teams_data,
        champions,
        champions_data,
        tags,
        tags_data,
        rangeDate,
        timerange,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Library';

  const fetchData = (
    userData,
    matchTypeData,
    sideData,
    teamsData,
    championsData,
    tagsData,
    startDateData,
    endDateData,
    timerangeData,
  ) => {
    setIsLoading(true);

    fetchLibrary(
      userData,
      matchTypeData,
      sideData,
      teamsData,
      championsData,
      tagsData,
      startDateData,
      endDateData,
      timerangeData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        library: {
          ...prevValue.library,
          user_data: res.users,
          teams_data: res.teams,
          champions_data: res.champions,
          tags_data: res.tags,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(
        user,
        matchType,
        side,
        teams,
        champions,
        tags,
        rangeDate[0],
        rangeDate[1],
        timerange,
      );
    }
  }, [user, matchType, side, teams, champions, tags, rangeDate, timerange]);

  const buttons = [
    {
      id: 1,
      btnText: 'Add link',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: libraryAddURL,
    },
  ];

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <PageTitle title={title} disableGutterBottom />
      </Box>
      <DataFilters
        userFilter={user_data}
        matchTypeFilter
        rangeDateFilter
        sideFilter
        teamsFilter={teams_data}
        tagsFilter={tags_data}
        championsFilter={champions_data}
        timerangeFilter
        type="library"
      />
      <AdminList
        buttons={buttons}
        tableCols={LIBRARY_GAMES_LIST_COLS}
        tableData={data}
        baseURL={libraryBaseURL}
        deleteFn={deleteLibrary}
        refreshFn={() =>
          fetchData(
            user,
            matchType,
            side,
            teams,
            champions,
            tags,
            rangeDate[0],
            rangeDate[1],
            timerange,
          )
        }
      />
    </>
  );
};

export default LibraryView;
