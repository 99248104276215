// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Routes
import { adminMatchesBaseURL } from 'routes/admin';

// Services
import { fetchMatches, deleteMatch } from 'services/matches';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_MATCHES_COLS } from 'constants/tableHead';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminMatchesView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = `Matches ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchMatches().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  return (
    <AdminList
      title={title}
      tableCols={ADMIN_MATCHES_COLS}
      tableData={data}
      baseURL={adminMatchesBaseURL}
      deleteFn={deleteMatch}
      refreshFn={fetchData}
    />
  );
};

export default AdminMatchesView;
