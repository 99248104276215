// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchPerfromance } from 'services/data';

// Constants
import { PERFORMANCE_TABLE } from 'constants/mrt';
import { MATCH_TYPES } from 'constants/match';
import { TEAM_PERFORMANCE_LIST_COLS, SCRIM_PERFORMANCE_LIST_COLS } from 'constants/tableHead';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import DeathsPerformanceModal from 'components/templates/PerformanceModal/PerformanceModal';

const ScrimsPerformanceView = () => {
  const {
    setTitleSEO,
    filters: {
      scrims: { rangeDate, side, patch, patches, opponent, opponents, blitz },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Scrims Performance';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const fetchData = (
    itemType,
    sideData,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchPerfromance(
      itemType,
      sideData,
      null,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        scrims: { ...prevValue.scrims, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(MATCH_TYPES.SCRIM, side, rangeDate[0], rangeDate[1], patch, opponent, blitz);
    }
  }, [rangeDate, side, patch, opponent, blitz]);

  const columns = useMemo(() => TEAM_PERFORMANCE_LIST_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...PERFORMANCE_TABLE(SCRIM_PERFORMANCE_LIST_COLS),
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters
            sideFilter
            rangeDateFilter
            patchesFilter={patches}
            opponentsFilter={opponents}
            blitzFilter
            type="scrims"
          />
          <MaterialReactTable table={table} />
          <DeathsPerformanceModal />
        </>
      }
    />
  );
};

export default ScrimsPerformanceView;
