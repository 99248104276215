// React
import React, { useContext, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminItemsURL } from 'routes/admin';

// Services
import { addItem } from 'services/items';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminItemsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    setIsLoading(false);

    if (!renderAfterCalled.current) {
      setTitleSEO(`Add - Items ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    id: '',
    name: '',
    image_url: '',
    ornn_item: false,
  };
  const validationSchema = Yup.object().shape({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
    image_url: Yup.string().url().required('Required'),
    ornn_item: Yup.boolean(),
  });
  const fields = [
    {
      id: 'id',
      label: 'ID',
      typeField: 'textfield',
    },
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
    {
      id: 'ornn_item',
      label: 'Ornn Item',
      typeField: 'checkbox',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields}
      formikFn={addItem}
      formikRedirect={adminItemsURL}
      formikBtnText="Add item"
    />
  );
};

export default AdminItemsAddView;
