// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminPlayersURL } from 'routes/admin';

// Services
import { fetchNationalities } from 'services/nationalities';
import { fetchAccountsAvailable } from 'services/accounts';
import { fetchTeams } from 'services/teams';
import { addPlayer } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ROLES } from 'constants/role';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminPlayersAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchNationalities().then((res) => setNationalityOptions(res));

    fetchTeams().then((res) => setTeamOptions(res));

    fetchAccountsAvailable().then((res) => setAccountOptions(res));

    setIsLoading(false);
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(`Add - Players ${ADMIN_TITLE_POSTFIX}`);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    role: '',
    provider_id: '',
    image_url: '',
    leaguepedia: '',
    birthday: '',
    lolpros: '',
    nationalities: [],
    teams: [],
    accounts: [],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    provider_id: Yup.string(),
    image_url: Yup.string().url(),
    leaguepedia: Yup.string().required('Required'),
    birthday: Yup.string(),
    lolpros: Yup.string(),
    nationalities: Yup.array(),
    teams: Yup.array(),
    accounts: Yup.array(),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'role',
      label: 'Role',
      typeField: 'select',
      options: ROLES,
      optionLabel: 'label',
      compareValue: 'value',
    },
    {
      id: 'provider_id',
      label: 'Provider ID',
      typeField: 'textfield',
    },
    {
      id: 'image_url',
      label: 'Image URL',
      typeField: 'textfield',
    },
  ];
  const fields2 = [
    {
      id: 'leaguepedia',
      label: 'Leaguepedia',
      typeField: 'textfield',
    },
    {
      id: 'birthday',
      label: 'Birthday',
      typeField: 'textfield',
    },
    {
      id: 'lolpros',
      label: 'LoLPros',
      typeField: 'textfield',
    },
    {
      id: 'nationalities',
      label: 'Nationalities',
      typeField: 'nationalities',
      loadData: {
        nationalities: nationalityOptions,
      },
    },
  ];
  const fields3 = [
    {
      id: 'accounts',
      label: 'Accounts',
      typeField: 'multiautocomplete',
      options: accountOptions,
    },
    {
      id: 'teams',
      label: 'Teams',
      typeField: 'teams',
      loadData: {
        roles: ROLES,
        teams: teamOptions,
      },
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFn={addPlayer}
      formikRedirect={adminPlayersURL}
      formikBtnText="Add player"
    />
  );
};

export default AdminPlayersAddView;
