// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Assets
import InfoIcon from '@mui/icons-material/Info';

// Elements
import { Tooltip } from '@mui/material';

// Component
const InfoTooltip = ({ data, cell, bigIcon }) => {
  const value = data || (cell && cell.getValue());

  return (
    <Tooltip title={value}>
      <InfoIcon color="info" sx={{ fontSize: bigIcon ? 18 : 14 }} />
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cell: PropTypes.object,
  bigIcon: PropTypes.bool,
};

InfoTooltip.defaultProps = {
  data: '',
  cell: {},
  bigIcon: false,
};

export default InfoTooltip;
