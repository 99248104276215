// React
import React, { useContext, useState, useEffect } from 'react';

// Context
import { Context } from 'context';

// Constants
import { BLUE_MAIN, GREEN_MAIN, RED_MAIN, TABLE_BACKGROUND_MAIN } from 'constants/color';

// Elements
import { Box, Modal, Backdrop, Typography } from '@mui/material';
import PerformanceData from 'components/organisms/PerformanceData/PerformanceData';

// Component
const PerformanceModal = () => {
  const { modalTableData, setModalTableData, isModalTableOpen, setIsModalTableOpen } =
    useContext(Context);
  const [badPerformance, setbadPerformance] = useState([]);
  const [neutralPerformance, setNeutralPerformance] = useState([]);
  const [goodPerformance, setGoodPerformance] = useState([]);
  const [isTeamFights, setIsTeamFights] = useState(false);

  useEffect(() => {
    const modalData = modalTableData?.data;
    const modalType = modalTableData?.type.includes('team_fights');

    if (modalData) {
      const badPerformanceData = [];
      const neutralPerformanceData = [];
      const goodPerformanceData = [];

      modalData.forEach((item) => {
        if (item.rate === 'B') {
          badPerformanceData.push(item);
        } else if (item.rate === 'N') {
          neutralPerformanceData.push(item);
        } else if (item.rate === 'G') {
          goodPerformanceData.push(item);
        }
      });

      setbadPerformance(badPerformanceData);
      setNeutralPerformance(neutralPerformanceData);
      setGoodPerformance(goodPerformanceData);
      setIsTeamFights(modalType);
    }
  }, [modalTableData]);

  const handleClose = () => {
    setIsModalTableOpen(false);
    setModalTableData(null);
  };

  return (
    <Modal
      open={isModalTableOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '75%',
          maxHeight: '80vh',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
      >
        <Box display="grid" gridTemplateColumns={`repeat(${!isTeamFights ? 3 : 2}, 1fr)`}>
          <Box borderRight={`1px solid ${TABLE_BACKGROUND_MAIN}`} p={2}>
            <Typography variant="h6" component="h3" textTransform="uppercase" color={RED_MAIN}>
              Bad
            </Typography>
            <PerformanceData data={badPerformance} isCol2={isTeamFights} />
          </Box>
          {!isTeamFights ? (
            <Box borderRight={`1px solid ${TABLE_BACKGROUND_MAIN}`} p={2}>
              <Typography variant="h6" component="h3" textTransform="uppercase" color={BLUE_MAIN}>
                Neutral
              </Typography>
              <PerformanceData data={neutralPerformance} isCol2={isTeamFights} />
            </Box>
          ) : null}
          <Box p={2}>
            <Typography variant="h6" component="h3" textTransform="uppercase" color={GREEN_MAIN}>
              Good
            </Typography>
            <PerformanceData data={goodPerformance} isCol2={isTeamFights} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PerformanceModal;
