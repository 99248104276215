// React
import React, { useContext, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminOptionsURL } from 'routes/admin';

// Services
import { addOption } from 'services/options';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminOptionsAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(`Add - Options ${ADMIN_TITLE_POSTFIX}`);

      setIsLoading(false);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    name: '',
    value: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
  });
  const fields1 = [
    {
      id: 'name',
      label: 'Name',
      typeField: 'textfield',
    },
    {
      id: 'value',
      label: 'Value',
      typeField: 'textfield',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFn={addOption}
      formikRedirect={adminOptionsURL}
      formikBtnText="Add option"
    />
  );
};

export default AdminOptionsAddView;
