// React
import React, { useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Constants
import { BLUE_MAIN, BLUE_A10, RED_MAIN, RED_A10 } from 'constants/color';

// Elements
import { Box, FormControlLabel, Switch as SwitchMUI } from '@mui/material';

// Component
const Switch = ({ id, label, value, color, onChange, type, mt }) => {
  const [checked, setChecked] = useState(value);
  const [tempColor, setTempColor] = useState(color);
  const [tempColorHover, setTempColorHover] = useState(null);
  const [tempLabel, setTempLabel] = useState(label);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onChange(id, event.target.checked);
  };

  useEffect(() => {
    if (type === 'side') {
      setTempColor(BLUE_MAIN);
      setTempColorHover(BLUE_A10);
      setTempLabel('BLUE');

      if (checked) {
        setTempColor(RED_MAIN);
        setTempColorHover(RED_A10);
        setTempLabel('RED');
      }
    }
  }, [checked, type]);

  return (
    <Box mt={mt}>
      <FormControlLabel
        label={tempLabel}
        labelPlacement="end"
        control={
          <SwitchMUI
            id={id}
            checked={checked}
            onChange={handleChange}
            sx={{
              '& .MuiSwitch-switchBase': {
                color: tempColor,
                '&:hover': {
                  backgroundColor: tempColorHover,
                },
              },
              '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                backgroundColor: tempColor,
              },
            }}
          />
        }
        sx={{ color: tempColor }}
      />
    </Box>
  );
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  mt: PropTypes.number,
};

Switch.defaultProps = {
  label: '',
  color: 'primary',
  type: '',
  mt: 0,
};

export default Switch;
