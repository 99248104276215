// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { BLACK_MAIN, WHITE_MAIN } from 'constants/color';

// Elements
import { Box, Chip } from '@mui/material';

// Component
const TagsData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue() || getValue().length === 0) {
    return null;
  }

  return (
    <Box display="grid" gap={1 / 2} width="100%">
      {getValue().map((item) => (
        <Chip
          key={item}
          label={item}
          size="small"
          sx={{
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 700,
            color: BLACK_MAIN,
            backgroundColor: WHITE_MAIN,
          }}
        />
      ))}
    </Box>
  );
};

TagsData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default TagsData;
