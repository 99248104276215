// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchTags = async (itemType) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}tags/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSingleTag = (id) =>
  axiosLoadingInstance
    .get(`tags/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addTag = (data) =>
  axiosInstance
    .post('tags/add/', data)
    .then((res) => ({ data: res.data, message: 'Tag has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateTag = (id, data) =>
  axiosInstance
    .put(`tags/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Tag has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteTag = (id) =>
  axiosInstance
    .delete(`tags/${id}/`)
    .then((res) => ({ data: res.data, message: 'Tag has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
