// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Routes
import { adminPlayersAddURL, adminPlayersBaseURL } from 'routes/admin';

// Services
import { importAccountsFromOtherResources } from 'services/accounts';
import { fetchPlayers, importAllImages, deletePlayer } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_PLAYERS_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminPlayersView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);
  const title = `Players ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchPlayers().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  const importAccounts = () => {
    setBtnLoading(true);

    importAccountsFromOtherResources().then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchData();

      setBtnLoading(false);
    });
  };

  const importImages = () => {
    setBtnLoading(true);

    importAllImages().then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchData();

      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const buttons = [
    {
      id: 1,
      btnText: 'Add player',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: adminPlayersAddURL,
    },
    {
      id: 2,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import accounts',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importAccounts,
    },
    {
      id: 3,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import images',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importImages,
    },
  ];

  return (
    <AdminList
      buttons={buttons}
      title={title}
      tableCols={ADMIN_PLAYERS_COLS}
      tableData={data}
      baseURL={adminPlayersBaseURL}
      deleteFn={deletePlayer}
      refreshFn={fetchData}
    />
  );
};

export default AdminPlayersView;
