// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Routes
import { adminServersAddURL, adminServersBaseURL } from 'routes/admin';

// Services
import { fetchServers, deleteServer } from 'services/servers';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_SERVERS_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminServersView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = `Servers ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchServers().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const buttons = [
    {
      id: 1,
      btnText: 'Add server',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: adminServersAddURL,
    },
  ];

  return (
    <AdminList
      buttons={buttons}
      title={title}
      tableCols={ADMIN_SERVERS_COLS}
      tableData={data}
      baseURL={adminServersBaseURL}
      deleteFn={deleteServer}
      refreshFn={fetchData}
    />
  );
};

export default AdminServersView;
