// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Routes
import { adminLeaguesAddURL, adminLeaguesBaseURL } from 'routes/admin';

// Services
import { fetchLeagues, deleteLeague } from 'services/leagues';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_LEAGUES_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminLeaguesView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = `Leagues ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchLeagues().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const buttons = [
    {
      id: 1,
      btnText: 'Add league',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: adminLeaguesAddURL,
    },
  ];

  return (
    <AdminList
      buttons={buttons}
      title={title}
      tableCols={ADMIN_LEAGUES_COLS}
      tableData={data}
      baseURL={adminLeaguesBaseURL}
      deleteFn={deleteLeague}
      refreshFn={fetchData}
    />
  );
};

export default AdminLeaguesView;
