// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchGames = () =>
  axiosLoadingInstance
    .get('games/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchGamesForCurrentTeam = () =>
  axiosLoadingInstance
    .get('games/current-team/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchReplayGameURL = (id) =>
  axiosInstance
    .get(`games/download/${id}/`, { responseType: 'blob', withCredentials: true })
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchReplayGameFilenameURL = (id) =>
  axiosInstance
    .get(`games/replay/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const importVODsFromLeaguepedia = () =>
  axiosInstance
    .get(`games/import-vods/`)
    .then((res) => ({ data: res.data, message: 'VODs have been imported!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleGameOverview = (id) =>
  axiosLoadingInstance
    .get(`games/overview/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleGameScoreboard = (id) =>
  axiosLoadingInstance
    .get(`games/scoreboard/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleGamePositionsOnMap = async (id, role, timerange) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}games/positions-on-map/${id}/`);

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSingleGameTeamFights = (id) =>
  axiosLoadingInstance
    .get(`games/team-fights/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleGameDraft = (id) =>
  axiosLoadingInstance
    .get(`games/draft/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleGame = (id) =>
  axiosLoadingInstance
    .get(`games/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchGameDataForLibrary = (id) =>
  axiosLoadingInstance
    .get(`games/library/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateGameDraft = (id, data) =>
  axiosInstance
    .put(`games/draft/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Game draft has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateGame = (id, data) =>
  axiosInstance
    .put(`games/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Game has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteGame = (id) =>
  axiosInstance
    .delete(`games/${id}/`)
    .then((res) => ({ data: res.data, message: 'Game has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
