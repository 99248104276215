// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Routes
import { adminItemsAddURL, adminItemsBaseURL } from 'routes/admin';

// Services
import { fetchItems, importItems, deleteItem } from 'services/items';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_ITEMS_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminItemsView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);
  const title = `Items ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchItems().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const importData = () => {
    setBtnLoading(true);

    importItems().then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      setBtnLoading(false);

      fetchData();
    });
  };

  const buttons = [
    {
      id: 1,
      btnText: 'Add item',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: adminItemsAddURL,
    },
    {
      id: 2,
      loadingState: btnLoading,
      loadingText: 'Importing...',
      btnText: 'Import',
      btnIcon: <RefreshIcon fontSize="small" />,
      btnFn: importData,
    },
  ];

  return (
    <AdminList
      buttons={buttons}
      title={title}
      tableCols={ADMIN_ITEMS_COLS}
      tableData={data}
      baseURL={adminItemsBaseURL}
      deleteFn={deleteItem}
      refreshFn={fetchData}
    />
  );
};

export default AdminItemsView;
