// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminGamesURL } from 'routes/admin';

// Services
import { fetchTeams } from 'services/teams';
import { fetchSingleGame, updateGame } from 'services/games';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminGamesEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [winnerOptions, setWinnerOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleGame(id).then((res) => {
      setData(res);

      setIsLoading(false);
    });

    fetchTeams().then((res) => setTeamOptions(res));
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.id} - Matches ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  useEffect(() => {
    if (data && teamOptions.length > 0) {
      setWinnerOptions(
        teamOptions.filter((team) => team.id === data.blue_team_id || team.id === data.red_team_id),
      );
    }
  }, [data, teamOptions]);

  const initialValues = {
    id: data?.id || '',
    match_id: data?.match_id || '',
    riot_id: data?.riot_id || '',
    provider_id: data?.provider_id || '',
    datetime: data?.datetime || '',
    blue_team_id: (teamOptions.length > 0 && data?.blue_team_id) || '',
    red_team_id: (teamOptions.length > 0 && data?.red_team_id) || '',
    winner_team_id: (winnerOptions.length > 0 && data?.winner_team_id) || '',
    duration: data?.duration || '',
    surrender: data?.surrender || false,
    blitz: data?.blitz || false,
    vod: data?.vod || '',
  };
  const validationSchema = Yup.object().shape({
    datetime: Yup.string().required('Required'),
    blue_team_id: Yup.number().required('Required'),
    red_team_id: Yup.number().required('Required'),
  });
  const fields = [
    {
      id: 'datetime',
      label: 'Datetime',
      typeField: 'textfield',
      disabled: true,
    },
    {
      id: 'blue_team_id',
      label: 'Blue Team',
      typeField: 'select',
      options: teamOptions,
      disabled: true,
    },
    {
      id: 'red_team_id',
      label: 'Red Team',
      typeField: 'select',
      options: teamOptions,
      disabled: true,
    },
    {
      id: 'winner_team_id',
      label: 'Winner Team',
      typeField: 'select',
      options: winnerOptions,
    },
    {
      id: 'vod',
      label: 'VOD',
      typeField: 'textfield',
    },
    {
      id: 'blitz',
      label: 'Blitz game',
      typeField: 'checkbox',
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields}
      formikFn={updateGame}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminGamesURL}
      formikBtnText="Update game"
    />
  );
};

export default AdminGamesEditView;
