export const DRAFT_TEAM_TYPE = [
  {
    id: 'ANY',
    name: 'ANY',
    value: 'ANY',
  },
  {
    id: 'TEAM',
    name: 'TEAM',
    value: 'TEAM',
  },
  {
    id: 'ENEMY',
    name: 'ENEMY',
    value: 'ENEMY',
  },
];

export const DRAFT_SIDE_TYPE = [
  {
    id: 'BOTH',
    name: 'BOTH',
    value: 'BOTH',
  },
  {
    id: 'BLUE',
    name: 'BLUE',
    value: 'BLUE',
  },
  {
    id: 'RED',
    name: 'RED',
    value: 'RED',
  },
];

export const SEARCH_TEAM_C1_TYPE = [
  {
    id: 'ANY',
    name: 'ANY',
    value: 'ANY',
  },
  {
    id: 'BLUE',
    name: 'BLUE',
    value: 'BLUE',
  },
  {
    id: 'RED',
    name: 'RED',
    value: 'RED',
  },
];

export const SEARCH_TEAM_TYPE = [
  {
    id: 'ANY',
    name: 'ANY',
    value: 'ANY',
  },
  {
    id: 'SAME',
    name: 'SAME AS C1',
    value: 'SAME',
  },
  {
    id: 'DIFFERENT',
    name: 'DIFFERENT FROM C1',
    value: 'DIFFERENT',
  },
];

export const GAME_LIBRARY_DATA = {
  id: null,
  date: 'YYYY-MM-DD',
  game: 'Game X',
  type: 'TYPE',
  team_1: 'BLUE',
  team_2: 'RED',
  blue_picks: [
    {
      id: 1,
      name: 'None',
      image_url: '',
    },
    {
      id: 2,
      name: 'None',
      image_url: '',
    },
    {
      id: 3,
      name: 'None',
      image_url: '',
    },
    {
      id: 4,
      name: 'None',
      image_url: '',
    },
    {
      id: 5,
      name: 'None',
      image_url: '',
    },
  ],
  red_picks: [
    {
      id: 1,
      name: 'None',
      image_url: '',
    },
    {
      id: 2,
      name: 'None',
      image_url: '',
    },
    {
      id: 3,
      name: 'None',
      image_url: '',
    },
    {
      id: 4,
      name: 'None',
      image_url: '',
    },
    {
      id: 5,
      name: 'None',
      image_url: '',
    },
  ],
};
