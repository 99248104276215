// React
import React, { useContext, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Services
import { updateSettingsUser } from 'services/settings';

// Schemas
import { PASSWORD_SCHEMA, PASSWORD_CHECK_SCHEMA } from 'schemas/users';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const SettingsView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const renderAfterCalled = useRef(false);
  const title = 'Settings';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      setIsLoading(false);
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    new_password: '',
    new_password_check: '',
  };
  const validationSchema = Yup.object().shape({
    new_password: PASSWORD_SCHEMA,
    new_password_check: PASSWORD_CHECK_SCHEMA,
  });
  const fields = [
    {
      id: 'new_password',
      label: 'New password',
      typeField: 'textfield',
      type: 'password',
    },
    {
      id: 'new_password_check',
      label: 'Repeat new password',
      typeField: 'textfield',
      type: 'password',
    },
  ];

  return (
    <>
      <PageTitle title={title} />
      <AdminManage
        initialValues={initialValues}
        validationSchema={validationSchema}
        formikType="edit"
        formikFields1={fields}
        formikFn={updateSettingsUser}
        formikBtnText="Change password"
      />
    </>
  );
};

export default SettingsView;
